<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <v-btn-toggle
        v-model="icon" 
        @change="handleChangeIsModified"
      >
        <v-btn :value="'mdi-information'" @click="content.Icon = 'mdi-information'" text>
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-btn :value="'mdi-alert'" @click="content.Icon = 'mdi-alert'" text>
          <v-icon>mdi-alert</v-icon>
        </v-btn>
        <v-btn :value="'mdi-help-circle'" @click="content.Icon = 'mdi-help-circle'" text>
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
        <v-btn :value="'mdi-check-circle'" @click="content.Icon = 'mdi-check-circle'" text>
          <v-icon>mdi-check-circle</v-icon>
        </v-btn>
        <v-btn :value="'mdi-email'" @click="content.Icon = 'mdi-email'" text>
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <v-btn :value="'mdi-clock'" @click="content.Icon = 'mdi-clock'" text>
          <v-icon>mdi-clock</v-icon>
        </v-btn>
         <v-btn :value="''" @click="content.Icon = ''" text>
           <v-icon style="opacity: 0.2">mdi-cancel</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;">
      <translate
        v-model="content.AlertText"
        :languages="languages"
        :label="$t('dtouch.linkDomain.name', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 10px 10px;"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.Color"
        :label="$t('common.color', locale)"
        :onChange="handleChangeColor"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import Translate from '@/components/Translate'
  import ColorPickerItem from '@/components/common-components/ColorPickerItem'
  export default {
    name: 'Form6Alert',
    components: {
      Translate,
      ColorPickerItem,
    },
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      content: null,
      icon: null,
    }),
    computed: {        
      ...mapState('app', ['locale']),
      languages () {
        return this.config.Languages
      },
    },
    watch: {
      editedItem () {
        this.prepareContent()
      },
    },
    mounted () {
      this.prepareContent()
    },
    methods: {
      checkJSON (v) {
        return typeof v == 'object' ? v : JSON.parse(v)
      },
      prepareContent () {
        let aux = this.editedItem
        aux.AlertText = this.checkJSON(aux.AlertText)
        this.content = aux
        this.icon = this.content.Icon
      },
      handleChangeColor (v) {
        this.content.Color = v
        this.handleChangeIsModified()
      },
      handleChangeIsModified () {
        this.onChangeIsModified(this.content) 
      },
    },
  }
</script>

