<template>
  <v-layout wrap v-if="content">
    <v-flex xs12 style="margin-top:20px;">
      <v-text-field
          v-model="content.PollAlias"
          :label="$t('dtouch.survey.ident', locale)"
          hideDetails
          @input="handleChangeIsModified"
          style="margin-bottom: 20px;"
        />
      <add-item :onAddItem="handleAddItem" />
      <v-card
        v-for="item in content.PollFormData"
        :key="item.id"
        style="margin-top:30px;padding: 20px;"
      >
        <v-btn
          absolute
          dark
          fab
          top
          right
          small
          color="red"
          @click="handleDeleteItem(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <translate
          v-model="item.question"
          :languages="config.Languages"
          :label="$t('dtouch.survey.question', locale)"
          @input="handleChangeIsModified"
          style="margin: 20px 0;"
        />
        <selection
          v-if="item.type==='multiSelection' || item.type==='singleSelection' "
          :item="item"
          :onAddAnswer="() => handleAddAnswer(item.id)"
          :onChangeIsModified="handleChangeIsModified"
          :config="config"
        />
        <v-layout wrap v-else-if="item.type==='number'">
          <v-flex xs6>
          <v-text-field
            v-model="item.min"
            type="number"
            label="Min"
          />
          </v-flex>
          <v-flex xs6>
          <v-text-field
            v-model="item.max"
            type="number"
            label="Max"
          />
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex' 
import utils from '@/services/utils'
import Translate from '@/components/Translate'
import AddItem from './AddItem'
import Selection from './Selection'
export default {
  name: 'Form35Poll',
  components: {
    AddItem,
    Selection,
    Translate,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    content: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    checkJSON (v) {
      return typeof v === 'string' ? JSON.parse(v) : v
    },
    prepareContent () {
      const aux = this.editedItem
      aux.PollFormData = this.checkJSON(aux.PollFormData)
      this.content = aux
    },
    handleChangeIsModified () {
      this.content.UpdateKey = utils.getNewID()
      this.onChangeIsModified(this.content) 
    },
    handleAddItem (v) {
      if (!this.content.PollFormData) this.content.PollFormData = []
      
      const newItem = {
        id: utils.getNewID(),
        type: v,
        question: {
          es: ''
        },
        values: []
      }

      if (v === 'number') {
        newItem.min = 0
        newItem.max = 100
      }

      this.content.PollFormData.push(newItem)
      this.content.UpdateKey = utils.getNewID()
    },
    handleAddAnswer (id) {
      this.content.PollFormData.filter(x => x.id === id) [0].values.push({
        id: utils.getNewID(),
        answer: {
          es: ''
        },
      })
      this.content.UpdateKey = utils.getNewID()
    },
    handleDeleteItem (v) {
      this.content.PollFormData = this.content.PollFormData.filter(x => x.id !== v)
    }
  }
}
</script>

