<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <v-select
        v-model="content.BookingActType"
        :items="types"
        :label="$t('booking.reservations.type', locale)"
        outlined
        @change="handleChangeIsModified"
        hide-details
        style="margin-bottom: 10px;"
      />
    </v-flex>
    <v-flex xs12
      v-if="bookingWorkspacesID"
    >
      <v-select
        v-model="content.BookingActWorkspaceID"
        item-text="Name"
        item-value="ID"
        :items="bookingWorkspacesID"
        :label="$t('booking.reservations.workspace', locale)"
        outlined
        @change="handleChangeFilterWorkspaceID"
        hide-details
        style="margin-bottom: 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-bottom: 20px">
      <v-select
        v-model="content.BookingActCategoryID"
        :items="categories"
        item-text="Name"
        item-value="ID"
        outlined
        :label="$t('booking.reservations.categories', locale)"
        @change="handleChangeIsModified"
        hide-details
      />
    </v-flex>
    <v-flex xs12 style="margin-bottom: 20px" v-if="allActivities">
      <v-select
        v-model="content.BookingActActivityID"
        :items="allActivities"
        item-text="Name"
        item-value="ID"
        outlined
        :label="$t('booking.reservations.activities', locale)"
        @change="handleChangeIsModified"
        hide-details
      />
      <!--v-autocomplete
        v-model="content.BookingActFilter"
        :items="allActivities"
        outlined
        chips
        small-chips
        :label="$t('booking.reservations.activities', locale)"
        hide-details
        itemValue="ID"
        itemText="Name"
      /-->
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingActTypeColor"
        :label="$t('booking.reservations.activityColor.typeColor', locale)"
        :onChange="handleChangeTypeColor"
        borderRadius="20px 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingActWeekPrimaryColor"
        :label="$t('booking.reservations.activityColor.weekPrimaryColor', locale)"
        :onChange="handleChangeWeekPrimaryColor"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingActWeekSecondaryColor"
        :label="$t('booking.reservations.activityColor.weekSecondaryColor', locale)"
        :onChange="handleChangeWeekSecondaryColor"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingActContentColor"
        :label="$t('booking.reservations.activityColor.contentColor', locale)"
        :onChange="handleChangeContentColor"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingActTitleColor"
        :label="$t('booking.reservations.activityColor.titleColor', locale)"
        :onChange="handleChangeTitleColor"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingActTimeColor"
        :label="$t('booking.reservations.activityColor.timeColor', locale)"
        :onChange="handleChangeTimeColor"
        borderRadius="0 0 20px 0"
      />
    </v-flex>


  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'Form47BookMyBookings',
  components: {
    ColorPickerItem,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
    categories: [],
    allActivities: null, // TODO
    bookingWorkspacesID: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    isNew () {
      return this.editedItem.ID === 'new'
    },
    languages () {
      return this.$store.state.app.languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    types () {
      return [
        'byActivities',
        'byDays',
        'byHours',
        'byEvents'
      ]
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/contentmanager-book/`)
        .then(response => {
          this.bookingWorkspacesID = response
          this.handleGetAllActivities()
          this.handleGetAllCategories()
        })
      if (this.isNew) {
        api.getItem ('dtouch', `v1/public/workspaces/`, this.workspaceID)
          .then((response) => {
            const config = response
            this.editedItem.BookingActTypeColor = config.BGColorSecundary
            this.editedItem.BookingActWeekPrimaryColor = config.BGColor
            this.editedItem.BookingActWeekSecondaryColor = config.BGColorSecundary
            this.editedItem.BookingActContentColor = config.ColorContent
            this.editedItem.BookingActTitleColor = config.BGColor
            this.editedItem.BookingActTimeColor = config.BGColor
            this.content = this.editedItem
            this.onChangeIsModified(this.content) 
          })
      } else {
        this.content = this.editedItem
      }
      /*
      const aux = utils.clone(this.editedItem)
      aux.TypeColor = aux.TypeColor ? aux.TypeColor : '#000000'
      aux.WeekPrimaryColor = aux.WeekPrimaryColor ? aux.WeekPrimaryColor : '#FFFFFF'
      this.content = aux
      */
    },
    async handleGetAllCategories () {
      try {
        this.categories = []
        const aux = []
        aux.push({ ID: null, Name: 'Todos' })
        for (const item of this.content.BookingActWorkspaceID ? [this.content.BookingActWorkspaceID] : this.bookingWorkspacesID.map(item => item.ID)) {
          aux.push(...await new Promise((resolve, reject) => api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/contentmanager-booking/${item}/categories`)
            .then(response => {
              resolve(response.map(item => {
                return {
                  ID: item.ID,
                  Name: utils.getTranslation(item.Name, this.locale)
                }
              }))
            })
            .catch(error => {
              reject(error)
            })
          ))
        }
        this.categories = aux
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetAllActivities () {
      try {
        this.allActivities = []
        const aux = []
        for (const item of this.content.BookingActWorkspaceID ? [this.content.BookingActWorkspaceID] : this.bookingWorkspacesID.map(item => item.ID)) {
          aux.push(...await new Promise((resolve, reject) => api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/contentmanager-booking/${item}/activities`)
            .then(response => {
              resolve(response.map(item => {
                return {
                  ID: item.ID,
                  Name: utils.getTranslation(item.Name, this.locale)
                }
              }))
            })
            .catch(error => {
              reject(error)
            })
          ))
      }
      this.allActivities = aux
      } catch (error) {
        console.log(error)
      }
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
    handleChangeFilterWorkspaceID () {
      this.handleGetAllActivities()
      this.handleChangeIsModified()
    },
    handleChangeTypeColor (v) {
      this.content.BookingActTypeColor = v
      this.handleChangeIsModified ()
    },
    handleChangeWeekPrimaryColor (v) {
      this.content.BookingActWeekPrimaryColor = v
      this.handleChangeIsModified ()
    },
    handleChangeWeekSecondaryColor (v) {
      this.content.BookingActWeekSecondaryColor = v
      this.handleChangeIsModified ()
    },
    handleChangeContentColor (v) {
      this.content.BookingActContentColor = v
      this.handleChangeIsModified ()
    },
    handleChangeTitleColor (v) {
      this.content.BookingActTitleColor = v
      this.handleChangeIsModified ()
    },
    handleChangeTimeColor (v) {
      this.content.BookingActTimeColor = v
      this.handleChangeIsModified ()
    },
  },
}
</script>
<style scoped>
  .allergens-main {
    width: 100%;
  }
  .allergens-item {
    float: left;
    padding-right: 10px;
  }
</style>

