<template>
  <v-layout wrap v-if="content">
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Form52Requests',
  components: {
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
    cmDviewcontentContentID: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    isNew () {
      return this.editedItem.ID === 'new'
    },
    languages () {
      return this.$store.state.app.languages
    },
  },
  watch: {
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>
<style scoped>
  .allergens-main {
    width: 100%;
  }
  .allergens-item {
    float: left;
    padding-right: 10px;
  }
</style>

