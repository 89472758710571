<template>
  <v-layout wrap v-if="content">
    <v-flex xs12 style="padding: 10px">
      <select-image 
        :image="content.IconListImage"
        :onChange="handleChangeImage"
        :locale="locale"
        :imageWidth="200"
        :imageHeight="200"
        :aspectRatio="1/1"
        :folder="workspaceID"
      />
    </v-flex>
    <v-flex xs12 style="padding: 10px">
      <translate
        v-model="content.IconListTitle"
        :languages="languages"
        :label="$t('dtouch.iconList.title', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs12 style="padding: 10px">
      <translate
        v-model="content.IconListDescription"
        :languages="languages"
        :label="$t('dtouch.iconList.description', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import SelectImage from '@/components/selectImage/Index'
import Translate from '@/components/Translate'
export default {
  name: 'Form37IconList',
  components: {
    SelectImage,
    Translate
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    content: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.config.Languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    checkJSON (v) {
      return typeof v == 'object' ? v : JSON.parse(v)
    },
    prepareContent () {
      const aux = this.editedItem
      aux.IconListTitle = this.checkJSON(aux.IconListTitle)
      aux.IconListDescription = this.checkJSON(aux.IconListDescription)
      this.content = aux
    },
    handleChangeImage (v) {
      if (v) {
        this.content.IconListImage = v.base64
        this.content.newImage = v
        this.handleChangeIsModified()
      }
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>

