<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <color-picker-item
        :value="content.DviewcontentMessagesTitleColor"
        :label="$t('dtouch.workspace.dviewcontentMessages.titleColor', locale)"
        :onChange="handleChangeTitleColor"
        borderRadius="20px 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.DviewcontentMessagesDescriptionColor"
        :label="$t('dtouch.workspace.dviewcontentMessages.descriptionColor', locale)"
        :onChange="handleChangeDescriptionColor"
        borderRadius="0 0 20px 0"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'Form55DviewcontentMessages',
  components: {
    ColorPickerItem,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    isNew () {
      return this.editedItem.ID === 'new'
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      if (this.isNew) {
        api.getItem ('dtouch', `v1/public/workspaces/`, this.workspaceID)
          .then((response) => {
            const config = response
            this.editedItem.DviewcontentMessagesTitleColor = config.BGColor
            this.editedItem.DviewcontentMessagesDescriptionColor = config.ColorContent
            this.content = this.editedItem
            this.handleChangeIsModified ()
            /*
            this.editedItem.BookingActTypeColor = config.BGColorSecundary
            this.editedItem.BookingActWeekPrimaryColor = config.BGColor
            this.editedItem.BookingActWeekSecondaryColor = config.BGColorSecundary
            this.editedItem.BookingActContentColor = config.ColorContent
            this.editedItem.BookingActTitleColor = config.BGColor
            this.editedItem.BookingActTimeColor = config.BGColor
            this.content = this.editedItem
            this.onChangeIsModified(this.content) 
            */
          })
      } else {
        this.content = this.editedItem
      }
    },
    handleChangeTitleColor (v) {
      this.content.DviewcontentMessagesTitleColor = v
      this.handleChangeIsModified ()
    },
    handleChangeDescriptionColor (v) {
      this.content.DviewcontentMessagesDescriptionColor = v
      this.handleChangeIsModified ()
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>

