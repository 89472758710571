<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="true"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="enterpriseColor"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{$t('common.addItem')}}
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('dtouch.survey.selectType')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                block
                @click="onAddItem('multiSelection')"
              >
                {{$t('dtouch.survey.multiSelection')}}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                block
                @click="onAddItem('singleSelection')"
              >
                {{$t('dtouch.survey.singleSelection')}}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                block
                @click="onAddItem('rating')"
              >
                {{$t('dtouch.survey.rating')}}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                block
                @click="onAddItem('number')"
              >
                {{$t('dtouch.survey.number')}}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  export default {
    props: {
      onAddItem: {
        type: Function,
        required: true
      }
    },
    data: () => ({
      fav: true,
      menu: false,
      message: false,
      hints: true,
    }),
    computed: {
      enterpriseColor () {
        return this.$store.state.app.enterpriseColor
      },
    },
  }
</script>
