<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingMyBookingsExpandColor"
        :label="$t('booking.reservations.myBookings.expandColor', locale)"
        :onChange="handleChangeExpandColor"
        borderRadius="20px 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingMyBookingsBtnColor"
        :label="$t('booking.reservations.myBookings.btnColor', locale)"
        :onChange="handleChangeBtnColor"
        borderRadius="0 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingMyBookingsBGColor"
        :label="$t('booking.reservations.myBookings.bgColor', locale)"
        :onChange="handleChangeBGColor"
        borderRadius="0 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingMyBookingsTicketBGColor"
        :label="$t('booking.reservations.myBookings.ticketBGColor', locale)"
        :onChange="handleChangeTicketBGColor"
        borderRadius="0 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.BookingMyBookingsTicketTextColor"
        :label="$t('booking.reservations.myBookings.ticketTextColor', locale)"
        :onChange="handleChangeTicketTextColor"
        borderRadius="0 0 20px 0"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'Form47BookMyBookings',
  components: {
    ColorPickerItem,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.$store.state.app.languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    }
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      const aux = this.editedItem
      this.content = aux
    },
    handleChangeBGColor (v) {
      this.content.BookingMyBookingsBGColor = v
      this.handleChangeIsModified ()
    },
    handleChangeExpandColor (v) {
      this.content.BookingMyBookingsExpandColor = v
      this.handleChangeIsModified ()
    },
    handleChangeBtnColor (v) {
      this.content.BookingMyBookingsBtnColor = v
      this.handleChangeIsModified ()
    },
    handleChangeTicketBGColor (v) {
      this.content.BookingMyBookingsTicketBGColor = v
      this.handleChangeIsModified ()
    },
    handleChangeTicketTextColor (v) {
      this.content.BookingMyBookingsTicketTextColor = v
      this.handleChangeIsModified ()
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>
<style scoped>
  .allergens-main {
    width: 100%;
  }
  .allergens-item {
    float: left;
    padding-right: 10px;
  }
</style>

