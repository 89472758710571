<template>
  <v-layout v-if="content" wrap>
    <v-flex xs6>
      <v-slider
        style="padding-top: 20px"
        v-model="content.DividerUp"
        color="primary"
        prepend-icon="mdi-menu-up"
        min="0"
        max="100"
        thumb-label
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs6>
      <v-slider
        style="padding-top: 20px"
        v-model="content.DividerDown"
        color="primary"
        append-icon="mdi-menu-down"
        min="0"
        max="100"
        thumb-label
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex
      xs6
    >
      <v-slider
        style="padding-top: 20px"
        v-model="content.DividerLeft"
        color="primary"
        prepend-icon="mdi-menu-left"
        min="0"
        max="100"
        thumb-label
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex
      xs6
    >
      <v-slider
        style="padding-top: 20px"
        v-model="content.DividerRight"
        color="primary"
        append-icon="mdi-menu-right"
        min="0"
        max="100"
        thumb-label
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        v-model="content.DividerDark"
        label="Dark"
        @change="handleChangeIsModified"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Form18Divider',
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true,
      },
    },
    data: () => ({
      content: null,
    }),
    computed: {        
      ...mapState('app', ['locale']),
    },
    watch: {
      editedItem () {
        this.prepareContent()
      },
    },
    mounted () {
      this.prepareContent()
    },
    methods: {
      prepareContent () {
        this.content = this.editedItem
      },
      handleChangeIsModified () {
        this.onChangeIsModified(this.content) 
      },
    },
  }
</script>

