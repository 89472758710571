<template>
  <v-expansion-panel
    @click="handleExpand"
  >
    <v-expansion-panel-header>
      {{ $t('dtouch.insertBetween.title', locale) }}
      <v-spacer />
    </v-expansion-panel-header>
    <v-expansion-panel-content 
      style="margin-top: 10px;"
    >
      <v-progress-linear
        indeterminate
        v-if="loading"
        color="primary"
      />
      <v-autocomplete
        v-if="items"
        v-model="selectedInsertBetween"
        :disabled="loading"
        :items="items"
        filled
        chips
        color="blue-grey lighten-2"
        item-text="name"
        item-value="id"
        hide-details
        @change="handleChangeInsertBetween"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
          >
            <v-avatar left>
              <v-icon>{{ data.item.icon }}</v-icon>
            </v-avatar>
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-avatar>
              <v-icon>{{ data.item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <!--v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle-->
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  name: 'InsertIntoBetweenPanel',
  props: {
    locale: {
      type: String,
      required: true
    },
    parentID: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    contentTypes: {
      type: Array,
      defult: null,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: null,
    selectedInsertBetween: null,
    loading: false
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  methods: {
    getData () {
      this.loading = true
      const url =  this.isMenu ? `v1/private/workspaces/${this.workspaceID}/menus/${this.parentID}/` : `v1/private/menus/${this.parentID}/contents/`
      api.getAll ('dtouch', url, 0, 10000)
        .then(response => {
          if (this.isMenu) {
            this.items = response.data.map(item => {
              return {
                id: item.ID,
                name: utils.getTranslation(item.Name, this.locale),
                icon: 'mdi-menu',
                ord: item.ORD,
              }
            })
          } else {
            this.items = response.data.map(item => {
              const aux = this.contentTypes.filter(x => x.id === item.Type)
              return {
                id: item.ID,
                name: item.Alias,
                icon: aux.length > 0 ? aux[0].icon : 'mdi-circle-small',
                ord: item.ORD,
              }
            })
          }
          this.loading = false
        })
    },
    handleExpand () {
      if (!this.items) this.getData()
    },
    handleChangeInsertBetween () {
      const aux = this.items.filter(x => x.id === this.selectedInsertBetween)
      if (aux && aux.length > 0) this.onChange(aux[0])
    },
  },
}
</script>

