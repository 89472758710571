<template>
  <v-layout wrap v-if="content">
    <v-flex xs6 style="margin-top: 10px;">
      <v-btn
        dark
        block
        @click="handleAddItem"
        color="primary"
      >
        <v-icon>mdi-plus</v-icon>{{ $t('dtouch.contact.addItem', locale) }}
      </v-btn>
    </v-flex>
    <v-flex xs6>
      <v-switch
        :value="content.HideDivider"
        :label="$t('dtouch.contact.hideDivider', locale)"
        color="primary"
        hide-details
        style="margin-top:10px;float: right; margin-right: 10px;"
        @change="handleChangeHideDivider"
      />
    </v-flex>
    <item
      v-for="(item, index) in content.ContactItems"
      :key="index"
      :content="item"
      :onDelete="handleDeleteItem"
      :onChange="handleChangeIsModified"
      :config="config"
    />
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import Item from './Item'
export default {
  name: 'Form5Iframe',
  components: {
    Item,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    content: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      const aux = this.editedItem
      aux.ContactItems = typeof aux.ContactItems === 'string' ? JSON.parse(aux.ContactItems) : aux.ContactItems
      this.content = aux
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
    handleAddItem () {
      this.content.ContactItems.push({
        ID: `new_${utils.getNewID()}`,
        ContactIcon: 2,
        ContactDescription: { es: ''},
        ContactURL: { es: ''},
      })
      this.handleChangeIsModified()
    },
    handleDeleteItem (v) {
      this.content.ContactItems = this.content.ContactItems.filter(x => x.ID !== v)
      this.handleChangeIsModified()
    },
    handleChangeHideDivider () {
      this.content.HideDivider = !this.content.HideDivider
      this.handleChangeIsModified()
    },
  },
}
</script>

