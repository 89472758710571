<template> 
  <v-layout wrap style="margin-top: 10px" class="contact-item">
    <v-flex xs12>
      <center>
      <v-btn-toggle
        v-model="content.ContactIcon" 
        @change="handleChangeIsModified"
      >
        <v-btn :value="'mdi-map-marker'" @click="content.ContactIcon = 'mdi-map-marker'" text>
          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
        <v-btn :value="'mdi-clock-outline'" @click="content.ContactIcon = 'mdi-clock-outline'" text>
          <v-icon>mdi-clock-outline</v-icon>
        </v-btn>
        <v-btn :value="'mdi-phone'" @click="content.ContactIcon = 'mdi-phone'" text>
          <v-icon>mdi-phone</v-icon>
        </v-btn>
        <v-btn :value="'mdi-whatsapp'" @click="content.ContactIcon = 'mdi-whatsapp'" text>
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
        <v-btn :value="'mdi-email'" @click="content.ContactIcon = 'mdi-email'" text>
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <v-btn :value="'mdi-web'" @click="content.ContactIcon = 'mdi-web'" text>
          <v-icon>mdi-web</v-icon>
        </v-btn>
        <v-btn :value="'mdi-youtube'" @click="content.ContactIcon = 'mdi-youtube'" text>
          <v-icon>mdi-youtube</v-icon>
        </v-btn>
        <v-btn :value="'mdi-twitter'" @click="content.ContactIcon = 'mdi-twitter'" text>
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
        <v-btn :value="'mdi-facebook'" @click="content.ContactIcon = 'mdi-facebook'" text>
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
        <v-btn :value="'mdi-linkedin'" @click="content.ContactIcon = 'mdi-linkedin'" text>
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>
        <v-btn :value="'mdi-instagram'" @click="content.ContactIcon = 'mdi-instagram'" text>
          <v-icon>mdi-instagram</v-icon>
        </v-btn>
      </v-btn-toggle>
      </center>
    </v-flex>
    <template v-if="isAddress">
      <v-flex xs6 style="padding-right: 5px;">
          <v-text-field
            v-model="lat"
            :label="$t('dtouch.contact.lat')"
            hideDetails
            @input="handleChangeLat"
            outlined
          />
        </v-flex>
        <v-flex xs6 style="padding-left: 5px;">
          <v-text-field
            v-model="lon"
            :label="$t('dtouch.contact.lon')"
            hideDetails
            @input="handleChangeLon"
            outlined
          />
      </v-flex>
      <v-flex xs12>
        <translate
          v-model="content.ContactDescription"
          :languages="languages"
          :label="$t('dtouch.contact.description')"
          @input="handleChangeIsModified"
          style="padding: 0 10px 0 10px;"
        />
      </v-flex>
    </template>
    <v-flex xs12 v-else>
      <translate
        v-model="content.ContactDescription"
        :languages="languages"
        :label="$t('dtouch.contact.description', locale)"
        @input="handleChangeIsModified"
        style="margin: 0 10px;"
      />
    </v-flex>
    <v-flex xs12 v-if="isURL">
      <translate
        v-model="content.ContactURL"
        :label="$t('dtouch.contact.url', locale)"
        :languages="languages"
        @input="handleChangeIsModified"
        style="margin: 0 10px;"
      />
    </v-flex>
    <v-flex xs12>
      <v-btn
        dark
        style="float: right"
        color="red"
        @click="onDelete(content.ID)"
      >
        {{$t('common.delete')}}
      </v-btn>
    </v-flex>
    <v-flex xs12>
      <v-divider />
    </v-flex>
    <!--v-flex xs12>
      {{ content }}
    </v-flex-->
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import Translate from '@/components/Translate'
export default {
  name: 'Form17ContanctItem',
  components: {
    Translate,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isURL: false,
    isAddress: false,
    lat: null,
    lon: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.config.Languages
    }
  },
  methods: {
    handleChangeIsModified () {
      this.check()
      this.onChange()
    },
    check() {
      this.isAddress = [
        'mdi-map-marker'
      ].indexOf(this.content.ContactIcon) >= 0 ? true : false

      this.isURL = [
          'mdi-email',
          'mdi-web',
          'mdi-youtube',
          'mdi-twitter',
          'mdi-facebook',
          'mdi-linkedin',
          'mdi-instagram'
        ].indexOf(this.content.ContactIcon) >= 0 ? true : false

      if (!this.isAddress) this.content.ContactLatLon = { Lat: '', Lon: '' }
      if (!this.isURL) this.content.ContactURL = { es: '', en: '' }
    },
    handleChangeLat () {
      if (!this.content.ContactLatLon) this.content.ContactLatLon = {}
      this.content.ContactLatLon.Lat = this.lat
      this.handleChangeIsModified()
    },
    handleChangeLon () {
      if (!this.content.ContactLatLon) this.content.ContactLatLon = {}
      this.content.ContactLatLon.Lon = this.lon
      this.handleChangeIsModified()
    },
  },
}
</script>
<style scoped>
.contact-item .flex {
  margin-top: 10px;
}
</style>

