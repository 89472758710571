<template>
  <v-layout wrap v-if="content">
    <v-flex xs12
      style="margin-bottom: 10px;"
    >
        <v-btn-toggle
          v-model="textAlign" 
          @change="handleChangeIsModified"
        >
          <v-btn :value="1" @click="content.Style.TextAlign = 'left'" text>
            <v-icon>mdi-format-align-left</v-icon>
          </v-btn>
          <v-btn :value="2" @click="content.Style.TextAlign = 'center'" text>
            <v-icon>mdi-format-align-center</v-icon>
          </v-btn>
          <v-btn :value="3" @click="content.Style.TextAlign = 'right'" text>
            <v-icon>mdi-format-align-right</v-icon>
          </v-btn>
          <v-btn :value="4" @click="content.Style.TextAlign = 'justify'" text>
            <v-icon>mdi-format-align-justify</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-btn-toggle
          v-model="style"
          multiple
          @change="handleChangeIsModified"
          style="margin-left:10px;"
        >
          <v-btn @click="content.Style.SizeMult = content.Style.SizeMult  + 1" text>
            <v-icon>mdi-format-font-size-increase</v-icon>
          </v-btn>
          <v-btn @click="content.Style.SizeMult = content.Style.SizeMult  - 1" text>
            <v-icon>mdi-format-font-size-decrease</v-icon>
          </v-btn>
          <v-btn @click="content.Style.ToUpperCase = !content.Style.ToUpperCase" text>
            <v-icon>mdi-format-letter-case-upper</v-icon>
          </v-btn>
          <!-- <v-btn :value="4" text>
            <v-icon>mdi-format-color-fill</v-icon>
          </v-btn> -->
        </v-btn-toggle>
        <custom-color-picker
          v-if="TextColor"
          :width="'50px !important'"
          :height="'47px'"
          :icon="'mdi-format-color-text'"
          :color="TextColor"
          :onChangeColor="handleChangeColor"
        />
    </v-flex>
    <v-flex xs12>
      <translate
        v-model="content.Title"
        :languages="languages"
        :label="$t('contentType.text')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import Translate from '@/components/Translate'
  export default {
    name: 'Form16Text',
    components: {
      Translate
    },
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      content: null,
      textAlign: null,
      style: null,
      TextColor: null,
    }),
    computed: {        
      ...mapState('app', ['locale']),
      languages () {
        return this.config.Languages
      },
    },
    watch: {
      editedItem () {
        this.prepareContent()
      },
    },
    mounted () {
      this.prepareContent()
    },
    methods: {
      checkJSON (v) {
        return typeof v == 'object' ? v : JSON.parse(v)
      },
      prepareContent () {
        const aux = this.editedItem
        aux.Title = this.checkJSON(aux.Title)
        aux.Style = this.checkJSON(aux.Style)
        this.content = aux
        this.textAlign = this.content.Style.TextAlign === 'left' ? 1 :
                        this.content.Style.TextAlign === 'center' ? 2 :
                        this.content.Style.TextAlign === 'right' ? 3 : 4
        this.style = this.content.Style.ToUpperCase ? [2] : []
      },
      handleChangeColor (color) {
        this.TextColor = color
        this.content.Style.TextColor = color
        this.handleChangeIsModified()
      },
      handleChangeIsModified () {
        this.onChangeIsModified(this.content) 
      },
    },
  }
</script>

