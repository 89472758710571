<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ $t('availability.title', locale) }}
      <div style="margin-left: 20px;width: 50px">
        <v-icon small v-if="status" :color="status.date && status.time ? 'green' : 'red'"> mdi-clock-time-three</v-icon>
        <!--v-icon v-if="status" :color="status.date ? 'green' : 'red'"> mdi-circle-medium</v-icon>
        <v-icon v-if="status" :color="status.time ? 'green' : 'red'"> mdi-circle-medium</v-icon-->
      </div>
      <v-spacer />
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <availability
        v-model="availability"
        :locale="locale"
        @change="handleChangeAvailability"
        inPanel
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import utils from '@/services/utils'
import Availability from './Availability'
export default {
  components: {
    Availability,
  },
  props: {
    availability: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      required: true
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    status: null
  }),
  computed: {
    timezone () {
      return this.$store.state.app.timezone
    },
  },
  mounted () {
    this.checkStatus(this.availability)
  },
  methods: {
    checkStatus (v) {
      if(v) this.status = utils.checkAvailability(v, 0, this.timezone)
      else this.status = null
    },
    handleChangeAvailability (v) {
      this.checkStatus(v)
      this.onChange(v)
    },
  },
}
</script>
