<template>
  <v-layout wrap v-if="content">
    <v-flex xs12 style="padding: 10px">
      <select-image 
        :image="content.SiteImage"
        :onChange="handleChangeImage"
        :locale="locale"
        :imageWidth="800"
        :imageHeight="600"
        :aspectRatio="16/9"
        :folder="workspaceID"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;">
      <translate
        v-model="content.SiteTitle"
        :languages="languages"
        :label="$t('dtouch.site.title', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 10px 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;background-color:#cccccc">
      <small style="margin-top: 20px;margin-left: 20px;">{{ $t('dtouch.site.address', locale) }}: </small>
      <v-layout wrap style="padding: 5px">
        <v-flex xs12 style="margin-top: 10px;">
          <v-text-field
            v-model="content.SiteLat"
            outlined
            :label="$t('dtouch.site.lat', locale)"
            hideDetails
            @input="handleChangeIsModified"
          />
        </v-flex>
        <v-flex xs12 style="margin-top: 10px;">
          <v-text-field
            v-model="content.SiteLon"
            outlined
            :label="$t('dtouch.site.lon', locale)"
            hideDetails
            @input="handleChangeIsModified"
          />
        </v-flex>
        <v-flex xs12 style="margin-top: 10px;">
          <v-text-field
            v-model="content.SiteAddress"
            outlined
            :label="$t('dtouch.site.address', locale)"
            hideDetails
            @input="handleChangeIsModified"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;">
      <translate
        v-model="content.SiteSubtitle"
        :languages="languages"
        :label="$t('dtouch.site.subtitle', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 10px 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;">
      <v-text-field
        v-model="content.SitePhone"
        outlined
        :label="$t('dtouch.site.phone', locale)"
        hideDetails
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;">
      <v-text-field
        v-model="content.SiteWeb"
        outlined
        :label="$t('dtouch.site.web', locale)"
        hideDetails
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;">
      <v-text-field
        v-model="content.SiteEmail"
        outlined
        :label="$t('dtouch.site.email', locale)"
        hideDetails
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;">
      <small>{{ $t('dtouch.site.moreInfo', locale) }}:</small>
      <translate
        v-model="content.SiteMoreInfo"
        type="richText"
        :languages="languages"
        :label="$t('dtouch.site.moreInfo', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 10px 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 10px;">
      <v-layout 
        align-center wrap 
        style="margin-top: 10px;padding:10px 10px 20px 10px;background-color:#cccccc80;"
      >
        <v-flex xs12>
          <v-checkbox
            v-model="content.SiteLink"
            :label="$t('dtouch.site.siteLink', locale)"
            @change="handleChangeIsModified"
          />
        </v-flex>
        <v-flex 
          v-if="content.SiteLink"
          xs4 
          style="padding-right: 5px;"
        >
          <translate
            v-if="content.SiteLinkURL"
            v-model="content.SiteLinkURL"
            :languages="languages"
            :label="$t('dtouch.site.siteLinkURL')"
            @input="handleChangeIsModified"
            style="padding: 15px 10px 0 10px;"
          />
        </v-flex>
        <v-flex 
          v-if="content.SiteLink"
          xs4 
          style="padding-right: 5px;"
        >
          <translate
            v-if="content.SiteLinkName"
            v-model="content.SiteLinkName"
            :languages="languages"
            :label="$t('dtouch.site.siteLinkName')"
            @input="handleChangeIsModified"
            style="padding: 15px 10px 0 10px;"
          />
        </v-flex>
        <v-flex 
          v-if="content.SiteLink"
          xs4 
          style="padding-left: 5px;"
        >
          <v-checkbox
            v-model="content.SiteLinkDialog"
            :label="$t('dtouch.site.siteLinkDialog')"
            hideDetails
            @change="handleChangeIsModified"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import Translate from '@/components/Translate'
import SelectImage from '@/components/selectImage/Index'
export default {
  name: 'Form20Site',
  components: {
    Translate,
    SelectImage,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    content: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.config.Languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    checkJSON (v) {
      return typeof v == 'object' ? v : JSON.parse(v)
    },
    prepareContent () {
      const aux = this.editedItem
      aux.SiteTitle = this.checkJSON(aux.SiteTitle)
      aux.SiteSubtitle = this.checkJSON(aux.SiteSubtitle)
      aux.SiteMoreInfo = this.checkJSON(aux.SiteMoreInfo)

      aux.SiteLinkURL = this.checkJSON(aux.SiteLinkURL)
      aux.SiteLinkName = this.checkJSON(aux.SiteLinkName)
      this.content = aux
    },
    handleChangeImage (v) {
      if (v) {
        this.content.SiteImage = v.base64
        this.content.newImage = v
        this.handleChangeIsModified()
      }
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>

