<template>
  <v-expansion-panel>
  <v-expansion-panel-header>{{ $t('contentType.position', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content
      v-if="padding"
      style="padding-top: 20px;"
    >
      <v-row no-gutters>
        <v-col cols="12" sm="4">
        </v-col>
        <v-col cols="12" sm="4"  class="justify-center" >
          <center class="position-center">
            <v-text-field
              solo
              v-model="padding[0]"
              hide-details
              dense
              filled
              type="number"
              reverse
              rounded
            />
          </center>
        </v-col>
        <v-col cols="12" sm="4">
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <center class="position-center">
            <v-text-field
              solo
              v-model="padding[3]"
              hide-details
              dense
              filled
              type="number"
              reverse
              rounded
            />
          </center>
        </v-col>
        <v-col cols="12" sm="4" />
        <!--v-col cols="12" sm="4" style="background-color: black;"
          :style="{padding: paddingSimulation}"
        >
          <div style="background-color: red;width: 100%; height: 100%"></div>
        </v-col-->
        <v-col cols="12" sm="4">
          <center class="position-center">
            <v-text-field
              solo
              v-model="padding[1]"
              hide-details
              dense
              filled
              type="number"
              reverse
              rounded
            />
          </center>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
        </v-col>
        <v-col cols="12" sm="4">
          <center class="position-center">
            <v-text-field
              solo
              v-model="padding[2]"
              hide-details
              dense
              filled
              type="number"
              reverse
              rounded
            />
          </center>
        </v-col>
        <v-col cols="12" sm="4">
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  name: 'ContentPadding',
  props: {
    locale: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    padding: null,
    paddingSimulation: '0px 10px 0px 0px'
  }),
  watch: {
    value (v) {
      if (!this.padding && v) this.preparePadding(v)
    },
    padding (v) {
      if (v) this.handleChange()
    }
  },
  mounted () {
    this.preparePadding(this.value)
  },
  methods: {
    preparePadding (v) {
      if (!v) {
        this.padding = [0, 0, 0, 0]
        return
      } 
      let aux = v.split(' ').join('')
      aux = v.split('px')
      if (aux.length === 5) aux.pop()
      this.padding = aux.length === 4 ? aux.map(item => item | 0) : null
    },
    handleChange () {
      if(this.padding) {
        const result = this.padding.join('px ').concat('px')
        this.$emit('change', result)
      }
    },
  }
}
</script>
<style scoped>
.position-center {
  padding: 10px;
}
</style>

