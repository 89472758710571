<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <translate
        v-model="content.LinkDomainName"
        :languages="languages"
        :label="$t('dtouch.linkDomain.name', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 10px 10px;"
      />

      <v-text-field
        v-model="content.LinkDomain"
        :label="$t('dtouch.linkDomain.domain', locale)"
        hideDetails
        outlined
        @input="handleChangeIsModified"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import Translate from '@/components/Translate'
  export default {
    name: 'Form28LinkDomain',
    components: {
      Translate
    },
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      content: null,
      textAlign: null,
      style: null,
      TextColor: null,
    }),
    computed: {        
      ...mapState('app', ['locale']),
      languages () {
        return this.config.Languages
      },
    },
    watch: {
      editedItem () {
        this.prepareContent()
      },
    },
    mounted () {
      this.prepareContent()
    },
    methods: {
      checkJSON (v) {
        return typeof v == 'object' ? v : JSON.parse(v)
      },
      prepareContent () {
        const aux = this.editedItem
        aux.LinkDomainName = this.checkJSON(aux.LinkDomainName)
        this.content = aux
      },
      handleChangeIsModified () {
        this.onChangeIsModified(this.content) 
      },
    },
  }
</script>

