<template>
  <v-layout wrap v-if="content" class="pdf-translate">
    <v-flex xs12 style="margin-top:10px;">
      <translate
        type="file"
        v-model="content.URLPdfTranslate"
        :languages="languages"
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        v-model="content.PDFTranslateLink"
        :value="content.PDFTranslateLink"
        @change="handleChangeIsModified"
        :label="$t('dtouch.pdfTranslate.openNewTab', locale)"
        hide-details
      />
    </v-flex>
    <v-flex xs12
      v-if="content.PDFTranslateLink && content.PDFTranslateLinkName" 
      style="padding: 20px 10px 0 10px"
    >
      <translate
        :label="$t('dtouch.pdfTranslate.btnText', locale)"
        v-model="content.PDFTranslateLinkName"
        :languages="languages"
        @input="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        v-model="content.DownloadPdfTranslate"
        :value="content.DownloadPdfTranslate"
        @change="handleChangeIsModified"
        :label="$t('dtouch.pdfTranslate.download', locale)"
        hide-details
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import Translate from '@/components/Translate'
  export default {
    name: 'Form31PDFTranslate',
    components: {
      Translate
    },
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      content: null,
    }),
    computed: {        
      ...mapState('app', ['locale']),
      languages () {
        return this.config.Languages
      },
    },
    watch: {
      editedItem () {
        this.prepareContent()
      },
    },
    mounted () {
      this.prepareContent()
    },
    methods: {
      checkJSON (v) {
        return typeof v == 'object' ? v : JSON.parse(v)
      },
      prepareContent () {
        const aux = this.editedItem
        aux.URLPdfTranslate = this.checkJSON(aux.URLPdfTranslate)
        aux.PDFTranslateLinkName = this.checkJSON(aux.PDFTranslateLinkName)
        this.content = aux
      },
      handleChangeIsModified () {
        this.onChangeIsModified(this.content) 
      },
    },
  }
</script>
<style scoped>
.pdf-translate .flex {
}
</style>

