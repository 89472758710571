<template>
  <v-layout wrap v-if="content">
    <v-flex xs12 style="margin-top: 10px;">
      <color-picker-item
        :value="content.ActtivPrimaryColor"
        :label="$t('dtouch.activities.primaryColor', locale)"
        :onChange="handleChangePrimaryColor"
        borderRadius="20px 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.ActtivSecundaryColor"
        :label="$t('dtouch.activities.secondaryColor', locale)"
        :onChange="handleChangeSecundaryColor"
        borderRadius="0 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.ActtivColor"
        :label="$t('dtouch.activities.titleColor', locale)"
        :onChange="handleChangeTitleColor"
        borderRadius="0 0 20px 0"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'Form34Acttiv',
  components: {
    ColorPickerItem,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    content: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      const aux = this.editedItem
      this.content = aux
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
    handleChangePrimaryColor (v) {
      this.content.ActtivPrimaryColor = v
      this.handleChangeIsModified()
    },
    handleChangeSecundaryColor (v) {
      this.content.ActtivSecundaryColor = v
      this.handleChangeIsModified()
    },
    handleChangeTitleColor (v) {
      this.content.ActtivColor = v
      this.handleChangeIsModified()
    },
  },
}
</script>

