<template>
  <v-layout wrap v-if="content">
    <v-flex xs12
      v-if="cmDviewcontentContentID"
    >
      <v-select
        v-model="content.WeeklyMenuContentID"
        item-text="Name"
        item-value="ID"
        :items="cmDviewcontentContentID"
        :label="$t('dtouch.workspace.weeklyMenu.contentID', locale)"
        outlined
        @change="handleChangeIsModified"
        hide-details
        style="margin-bottom: 10px;"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.WeeklyMenuWeekColor1"
        label="Semana color 1"
        :onChange="handleChangeWeekColor1"
        borderRadius="20px 0 0 0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.WeeklyMenuWeekColor2"
        label="Semana color 2"
        :onChange="handleChangeWeekColor2"
        borderRadius="0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.WeeklyMenuColor1"
        label="Color 1"
        :onChange="handleChangeColor1"
        borderRadius="0"
      />
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="content.WeeklyMenuColor2"
        label="Color 2"
        :onChange="handleChangeColor2"
        borderRadius="0 0 20px 0"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'Form51WeeklyMenu',
  components: {
    ColorPickerItem,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
    cmDviewcontentContentID: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    isNew () {
      return this.editedItem.ID === 'new'
    },
    languages () {
      return this.$store.state.app.languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/weekly-menu-content/`)
        .then(response => {
          this.cmDviewcontentContentID = response
          console.log(this.cmDviewcontentContentID)
        })
      if (this.isNew) {
        this.content = this.editedItem
        this.onChangeIsModified(this.content)
      } else {
        this.content = this.editedItem
      }
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
    handleChangeWeekColor1 (v) {
      this.content.WeeklyMenuWeekColor1 = v
      this.handleChangeIsModified()
    },
    handleChangeWeekColor2 (v) {
      this.content.WeeklyMenuWeekColor2 = v
      this.handleChangeIsModified()
    },
    handleChangeColor1 (v) {
      this.content.WeeklyMenuColor1 = v
      this.handleChangeIsModified()
    },
    handleChangeColor2 (v) {
      this.content.WeeklyMenuColor2 = v
      this.handleChangeIsModified()
    },
  },
}
</script>
<style scoped>
  .allergens-main {
    width: 100%;
  }
  .allergens-item {
    float: left;
    padding-right: 10px;
  }
</style>

